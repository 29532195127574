<template>
  <div id="charge-history-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- plan filter -->
        <v-col
          cols="12"
          sm="4"
        >
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="chef-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="chargeHistoryListTable"
        :options.sync="options"
        :server-items-length="totalChargeHistoryListTable"
        :loading="loading"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-chef-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- Rate -->
        <template #[`item.rate`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">5</span>
        </template>

        <!-- plan -->
        <template #[`item.currentPlan`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click=""
              >
                <router-link
                  :to="{ name : 'apps-charge-view', params :{id:item.id } }"
                >
                  <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
                </router-link>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { mdiEyeOutline } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import chargeHistoryStoreModule from '../chargeHistoryStoreModule'
import useChargeHistoryList from './useChargeHistoryList'

export default {
  setup(props, { emit }) {
    const CHARGE_HISTORY_APP_STORE_MODULE_NAME = 'app-charge-history'

    // Register module
    if (!store.hasModule(CHARGE_HISTORY_APP_STORE_MODULE_NAME)) {
      store.registerModule(CHARGE_HISTORY_APP_STORE_MODULE_NAME, chargeHistoryStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHARGE_HISTORY_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CHARGE_HISTORY_APP_STORE_MODULE_NAME)
      }
    })
    const {
      chargeHistoryListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalChargeHistoryListTable,
      loading,
      options,
      chargeHistoryTotalLocal,
      selectedRows,

      fetchCharges,
    } = useChargeHistoryList()

    return {
      chargeHistoryListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalChargeHistoryListTable,
      loading,
      options,
      chargeHistoryTotalLocal,
      selectedRows,
      avatarText,
      fetchCharges,

      // icons
      icons: {
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
