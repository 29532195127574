import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useChargeHistoryList() {
  const chargeHistoryListTable = ref([])

  const tableColumns = [
    { text: '#ID', value: 'id' },
    { text: 'Charging date', value: 'date' },
    { text: 'Total Points', value: 'points' },
    { text: 'Number of Employees', value: 'count_employees' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalChargeHistoryListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const chargeHistoryTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchCharges = () => {
    store
      .dispatch('app-charge-history/fetchCharges', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total, chargeHistoryTotal } = response.data.data
        chargeHistoryListTable.value = filteredData
        totalChargeHistoryListTable.value = total
        chargeHistoryTotalLocal.value = chargeHistoryTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchCharges()
  })

  return {
    chargeHistoryListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalChargeHistoryListTable,
    loading,
    options,
    chargeHistoryTotalLocal,
    selectedRows,
    fetchCharges,
  }
}
